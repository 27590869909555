export const messages = {
  header: {
    logo: 'Memori logo'
  },
  footer: {
    logo: 'Memori logo',
    servicesLabel: 'Diensten',
    contentLabel: 'Content',
    contactLabel: 'Memori',
    copyright: '© {year} Memori',
    privacyInformation: 'Privacybeleid',
    cookieStatement: 'Cookieverklaring',
    privacyStatement: 'Privacyverklaring',
    slogan: 'Monuments for Memories'
  },
  menu: {
    label: 'Hoofd menu',
    close: 'Sluit menu'
  },
  searchBar: {
    placeholder: 'Zoek een overledenen op naam of woonplaats',
    searchButton: 'Voer zoek opdracht uit',
    noResults: 'Geen resultaten gevonden voor {searchTerm}'
  },
  banner: {
    monumentsMap: {
      title: 'Bermmonumenten',
      description:
        'Bermmonumenten langs wegen herdenken tragische ongevallen en dienen als ontmoetingsplaatsen voor rouwende families en vrienden. Deze gedenktekens drukken emotie uit en benadrukken verkeersveiligheid, waarbij ze aandacht vragen voor de risico’s van roekeloos rijden. Ze herinneren aan het belang van',
      button: 'Bekijk bermonumenten in {name}'
    },
    orderFlowers: {
      image: 'Foto van een boeket',
      title: 'Bloemen laten bezorgen',
      text: 'Denkt u aan iemand die een steun in de rug nodig heeft en wilt u graag een bloemetje laten bezorgen.',
      button: 'Bloemen laten bezorgen'
    },
    supportWithReacting: {
      title: 'Hulp om te reageren',
      text: 'Een overlijden brengt emoties met zich mee die niet altijd even makkelijk onder woorden te brengen zijn. Een toepasselijke tekst kan de nabestaanden veel steun geven. Een rouwgedicht is vaak een uitkomst. Wij hebben hier een aantal rouwgedichten en teksten verzameld.',
      button: 'Bekijk gedichten en teksten'
    },
    stayUpToDate: {
      title: 'Op de hoogte blijven',
      description:
        'Wilt u op de hoogte blijven wanneer er iets gebeurd in deze woonplaats schrijf u dan in voor de berichten. Wij sturen u alleen relevante informatie.',
      email: 'Uw e-mailadres',
      emailPlaceholder: 'Typ hier uw e-mailadres',
      button: 'Aanmelden'
    }
  },
  dataTable: {
    totalCount: '{number} resultaten',
    filter: 'Filteren',
    sort: 'Sorteren',
    filterAndSortButton: 'Filter & sorteer'
  },
  card: {
    deceasedPerson: {
      age: '- {age} jaar -',
      view: 'Bezoek monument'
    },
    burialSite: {
      view: 'Bekijk begraafplaats >'
    }
  },
  modal: {
    close: 'Annuleer',
    lightCandle: {
      title: 'Brand een kaars',
      subtitle: 'ter nagedachtenis',
      text: 'Brand een kaarsje ter nagedachtenis aan {name}',
      button: 'Kaarsje aansteken'
    },
    makeMessageForHeirs: {
      title: 'Bericht voor nabestaanden',
      subtitle: 'ter nagedachtenis',
      messageIcon: 'Icoontje van een bericht',
      text: 'Laat een bericht achter voor de nabestaanden van {name}',
      button: 'Bericht achterlaten',
      success: 'Uw bericht is succesvol verstuurd naar de nabestaanden',
      form: {
        type: {
          memory: 'Herinnering',
          condolence: 'Condoleance',
          label: 'Wat voor type bericht zou u willen delen met de nabestaanden?',
          errorIncorrectType: 'Het type bericht moet 1 van de 2 opties zijn',
          error: 'U moet een type bericht selecteren'
        },
        firstName: {
          error: 'U moet een voornaam invullen',
          label: 'Voornaam',
          placeholder: 'Typ hier uw voornaam'
        },
        surname: {
          error: 'U moet een achternaam invullen',
          label: 'Achternaam',
          placeholder: 'Typ hier uw achternaam'
        },
        email: {
          emailError: 'Het email adres is niet valide',
          error: 'U moet een e-mail adres invullen',
          label: 'E-mailadres',
          placeholder: 'Typ hier uw e-mailadres'
        },
        message: {
          error: 'U moet een bericht invullen',
          label: 'Jouw bericht aan de nabestaanden',
          placeholder: 'Typ hier uw bericht ...'
        }
      }
    }
  },
  pages: {
    index: {
      mainSection: {
        title: 'Monumenten en overlijdensberichten',
        subtitle: 'Monuments for Memories',
        createMemoryButton: 'Maak een memori',
        viewExampleMemoryButton: 'Bekijk een voorbeeld'
      },
      quoteSection: {
        title: 'Quote van de dag'
      },
      inspireSection: {
        title: 'Inspiratie om te herrinneren',
        readMoreButton: 'Ik wil meer weten'
      }
    },
    city: {
      seo: {
        titleSimple: 'Overlijdensberichten en monumenten in {name} - Memori',
        title: 'Overlijdensberichten en monumenten in {name} onderdeel van {municipality} - Memori',
        description:
          'Bekijk alle overlijdensberichten en monumenten in {name}. Hier kunt u een monument maken voor een overledene uit {name}.'
      },
      mainSection: {
        title: 'Monumenten en overlijdensberichten',
        subtitle: '{name} herdenkt',
        municipalityInfo: 'Gemeente {name}',
        municipalityInfoIcon: 'Icoontje van een google maps pin',
        scrollDown: 'Meer informatie'
      },
      statics: {
        communityIcon: 'Icoontje die de verbondenheid van de gemeente of plaats weergeeft',
        about:
          'Samenleven in {name} verenigt ons vanuit de plek waar ons hart zich bevindt. Binnen deze cirkel van verbondenheid ontdekken we tevens andere elementen die ons nader tot elkaar brengen, waar we momenten van reflectie kunnen koesteren voor onze geliefden die ons zijn ontvallen.',
        title: 'Voor overledenen en nabestaanden uit {name}',
        candleIcon: 'Icoontje van een aantal ontstoken kaarsen',
        candlesLight: 'Kaarsen gebrand',
        flowerIcon: 'Icoontje van een aantal bloemen',
        flowersGifted: 'Bloemen gelegd',
        reactionIcon: 'Icoontje van een aantal reacties',
        reactionsLeft: 'Reacties geplaatst',
        shareDescription:
          'Dit is de overzichts pagina van de plaats of gemeente {name}. Hier vind u alle overlijdensberichten en monumenten die geplaatst zijn voor de overledenen uit {name}.',
        shareText: 'Deel via'
      },
      deceasedGrid: {
        title: 'Overledenen uit {name}',
        funeralStoneIcon: 'Icoontje van een grafsteen',
        numberOfDeceased: '{number} overledenen',
        viewMonumentButton: 'Bekijk monument',
        viewAllDescription: 'Wilt u alle overlijdensberichten van {name} bekijken?',
        viewAllButton: 'Bekijk alle overlijdensberichten'
      },
      monumentsReferral: {
        title: 'Monumenten in {name} om te herinneren',
        description:
          'Monumenten, tijdloze symbolen van geschiedenis en cultuur, verbinden ons met het verleden. Ze zijn getuigen van triomfen, offers en menselijke creativiteit, en inspireren respect voor onze erfgoed.',
        button: 'Naar monumenten overzicht'
      },
      relatedCities: {
        title: 'Vind meer overledenen in deze regio',
        description:
          'Bekenden wonen niet altijd in dezelfde woonplaats en je krijgt niet altijd mee wie er overlijdt. Veel mensen wil toch de familie van een bekende iets laten weten of een hart onder de riem steken. Hieronder een overzicht van alle woonplaatsen uit de gemeente waarvan {name} deel van uitmaakt.',
        viewAllDescription: 'Alle woonplaatsen in Nederland bekijken?',
        viewAllButton: 'Bekijk alle woonplaatsen'
      },
      burialSites: {
        title: 'Begraafplaatsen in de regio {name}',
        viewAllDescription: 'Wilt u een overzicht van alle begraafplaatsen in uw gemeente?',
        viewAllButton: 'Begraafplaatsen in uw gemeente'
      },
      regionalHelp: {
        title: 'Hulp uit eigen regio',
        description:
          'U hoeft het niet allemaal alleen te doen. Een specialist rondom overlijden uit uw eigen regio kan erg fijn zijn. Zij kennen de gemeenschap waar u woont en kan dichterbij u staan als mens. Heeft u hulp nodig dan kunt u ook hier altijd terecht.',
        viewAllDescription:
          'Bent u een organisatie in de regio {name} en denkt u te kunnen bijdragen aan het welzijn van mensen rondom het onderwerp overlijden verzoeken wij u contact op te nemen met ons.',
        viewAllButton: 'Bekijk de mogelijkheden'
      }
    },
    deceasedOverview: {
      seo: {
        title: 'Overlijdensbericht van {name} - Memori',
        description:
          'Bekijk het overlijdensbericht van {name}. Hier kunt u een monument maken voor {name}.'
      },
      mainSection: {
        photo: 'De foto van {name}',
        age: '{age} jaar',
        birthIcon: 'Icoontje van een ooienvaar',
        deceasedIcon: 'Icoontje van een ster',
        backgroundImage: 'Achtergrond foto van {name}'
      },
      shareAMemory: {
        candleIcon: 'Icoontje van een aantal ontstoken kaarsen',
        candlesLight: 'Inmiddels {number} kaarsen ontstoken voor {name}',
        advertDescription:
          'Dit is het overlijdensbericht van {name}. Dit bericht is ter kennisgeving van het overlijden van {name}. Dit bericht is geplaatst door de nabestaanden. Wilt u hen een bericht sturen of een kaarsje aansteken zodat men weet dat er aan ze gedacht wordt kan dat hier.',
        advertLightCandleButton: 'Kaarsje aansteken',
        advertLeaveMessageButton: 'Bericht sturen',
        advertShare: 'Deel overlijdensbericht via',
        title: 'Een herinnering delen',
        subtitle: 'over {name}',
        text: '{0} Berichten bij overlijden delen liefde en medeleven, en helpen de pijn van afscheid enigszins te verzachten. Samen herinneren we en eren we degenen die we verloren zijn.',
        strongText:
          'Herinneringen zijn kostbaar bij het verlies van een dierbare. Ze vormen een brug tussen verleden en heden, en bieden troost en kracht.',
        textNote: 'Berichten die hier geplaatst worden zijn alleen zichtbaar voor de nabestaanden.',
        textImage: 'Een foto uitgezocht voor {name}',
        sendAMessageButton: 'Een bericht sturen',
        requireAidLink: 'Heb je hulp nodig ? >',
        numberOfMessagesLeft: '{number} berichten achtergelaten',
        messageMemory: 'Bericht toevoegen',
        photoMemory: 'Foto toevoegen',
        musicMemory: 'Muziek toevoegen',
        videoMemory: 'Video toevoegen'
      },
      lightACandle: {
        title: 'Brand een kaars',
        subtitle: 'ter nagedachtenis',
        candleIcon: 'Icoontje van ontstoken kaarsen',
        text: 'Brand hier ook een kaars. U kunt dit met of zonder uw naam doen en net zo vaak als u wilt.',
        lightCandleButton: 'Kaarsje branden',
        candlesLight: '{number} kaarsen ontstoken'
      },
      morticianInformation: {
        title: 'Dienstverlener bij uitvaart'
      }
    }
  }
}
