import { messages as nl } from '~/i18n/nl'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'nl',
  fullInstall: true,
  globalInjection: true,
  messages: {
    nl
  }
}))
